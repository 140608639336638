<template>
  <div class="relatorio-dre-init">
    <v-container fluid>
      <h1 class="titulo">Feriados</h1>
      <v-row justify="end">
        <v-col md="2">
          <v-text-field
            v-model="holidayDescription"
            label="Descrição do feriado"
            :error-messages="holidayDescriptionErrors"
            @blur="$v.holidayDescription.$touch()"
          />
        </v-col>
        <v-col md="3">
          <v-menu :close-on-content-click="false" :nudge-right="35" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-combobox 
                v-model="formattedDate"
                label="Data do feriado"
                prepend-icon="mdi-calendar" 
                readonly
                multiple
                small-chips
                v-on="on"
                v-bind="attrs"
                :error-messages="holidayDateErrors"
                @blur="$v.date.$touch()"
              />
            </template>
            <v-date-picker
              multiple
              v-model="date"
              header-color="blue"
              color="blue"
              :events="holidaysDates"
              event-color="red"
              @input="formatarData()"
            />
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right">
          <v-btn @click.prevent="submit()" color="info" :loading="isLoading" @mouseover="visible = true">Cadastrar Feriado</v-btn>
        </v-col>
      </v-row>
      <template>
        <v-data-table
          :headers="headers"
          :items="holidays"
          :items-per-page="15"
        ></v-data-table>
      </template>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment'
import { map } from 'lodash'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'

const namespaceStore = 'feriados'

export default {
  name: 'Feriados',
  mixins: [],
  data: () => ({
    headers: [
      { text: 'Data', value: 'ref_date'} ,
      { text: 'Descrição do feriado', value: 'description', sortable: false },
      { text: 'Loterias', value: 'lotteries', sortable: false },
      { text: 'Ações', value: 'action', sortable: false }
    ],
    isLoading: false,
    holidayDescription: '',
    date: [],
    formattedDate: []
  }),
  mounted () {
    let self = this
    self.findHolidays()
  },
  computed: {
    ...mapGetters(namespaceStore, ['holidaysDates', 'holidays']),
    holidayDescriptionErrors () {
      const errors = []
      if (!this.$v.holidayDescription.$dirty) return errors
      !this.$v.holidayDescription.required && errors.push('Campo obrigatório.')
      return errors
    },
    holidayDateErrors () {
      const errors = []
      if (!this.$v.date.$dirty) return errors
      !this.$v.date.required && errors.push('Campo obrigatório.')
      return errors
    }
  },
  methods: {
    ...mapActions(namespaceStore, ['addHoliday', 'getHolidaysDates', 'getHolidays']),
    submit () {
      let self = this
      if (!self.$v.$invalid) {
        self.isLoading = true
        self.addHoliday({refDate: self.date, description: self.holidayDescription}).then(() => {
          self.findHolidays()
        }).catch(() => {
          self.isLoading = false
        })
      } else {
        self.$v.$touch()
      }
    },
    findHolidays () {
      let self = this
      self.getHolidaysDates().then(() => {
        self.isLoading = false
      })
      self.getHolidays()
    },
    formatarData () {
      const self = this
      self.formattedDate = map(self.date, item => moment(item).format('DD/MM/YYYY'))
    },
  },
  validations () {
    return {
      holidayDescription: { required },
      date: { required }
    }
  }
}
</script>